
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as _91slug_93ppPVNSaYckMeta } from "/app/pages/aktuell/[slug].vue?macro=true";
import { default as indexESrkZUUjThMeta } from "/app/pages/aktuell/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexYImWdYxV4zMeta } from "/app/pages/radio/audiothek/index.vue?macro=true";
import { default as _91slug_93Dgz5O7JKfmMeta } from "/app/pages/radio/ausgaben/[slug].vue?macro=true";
import { default as feedbackBi8duKHbdPMeta } from "/app/pages/radio/feedback.vue?macro=true";
import { default as programmGh4wPpzUpIMeta } from "/app/pages/radio/programm.vue?macro=true";
import { default as _91slug_935lsJUqNXRdMeta } from "/app/pages/radio/sendungen/[slug].vue?macro=true";
import { default as indexrRU2qDLZpTMeta } from "/app/pages/radio/sendungen/index.vue?macro=true";
import { default as tracklistCf6XcrqQepMeta } from "/app/pages/radio/tracklist.vue?macro=true";
import { default as sprachenknr97HfpHHMeta } from "/app/pages/sprachen.vue?macro=true";
import { default as sucheaAHUCj4Y6VMeta } from "/app/pages/suche.vue?macro=true";
import { default as lora_45info9dB9zGrbhsMeta } from "/app/pages/ueber-uns/lora-info.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin.vue")
  },
  {
    name: "aktuell-slug",
    path: "/aktuell/:slug()",
    component: () => import("/app/pages/aktuell/[slug].vue")
  },
  {
    name: "aktuell",
    path: "/aktuell",
    component: () => import("/app/pages/aktuell/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "radio-audiothek",
    path: "/radio/audiothek",
    component: () => import("/app/pages/radio/audiothek/index.vue")
  },
  {
    name: "radio-ausgaben-slug",
    path: "/radio/ausgaben/:slug()",
    component: () => import("/app/pages/radio/ausgaben/[slug].vue")
  },
  {
    name: "radio-feedback",
    path: "/radio/feedback",
    component: () => import("/app/pages/radio/feedback.vue")
  },
  {
    name: "radio-programm",
    path: "/radio/programm",
    component: () => import("/app/pages/radio/programm.vue")
  },
  {
    name: "radio-sendungen-slug",
    path: "/radio/sendungen/:slug()",
    component: () => import("/app/pages/radio/sendungen/[slug].vue")
  },
  {
    name: "radio-sendungen",
    path: "/radio/sendungen",
    component: () => import("/app/pages/radio/sendungen/index.vue")
  },
  {
    name: "radio-tracklist",
    path: "/radio/tracklist",
    component: () => import("/app/pages/radio/tracklist.vue")
  },
  {
    name: "sprachen",
    path: "/sprachen",
    component: () => import("/app/pages/sprachen.vue")
  },
  {
    name: "suche",
    path: "/suche",
    component: () => import("/app/pages/suche.vue")
  },
  {
    name: "ueber-uns-lora-info",
    path: "/ueber-uns/lora-info",
    component: () => import("/app/pages/ueber-uns/lora-info.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/home",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/programm",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/programm/tagesprogramm/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/programm/wochenprogramm",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/programm/aktuelle-sendungen",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/mitglied-werden/hazte-miembro",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/mitglied-werden/ueyelik",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/mitglied-werden/become-a-member",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/sendung-machen/make-your-own-show",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/sendung-machen/hacer-tu-propio-programa",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/sendung-machen/kendi-yay-n-n",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/sendung-machen/seu-proprio-programa-de-radio",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/sendung-machen/makrgow",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mitmachen/spenden/*",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/programm/lora-memo-archiv",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/aktuell/lorainfo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/radia",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/radia/feministische-redaktion",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/radia/geschichte",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/inforedaktion",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/musik",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ueberuns",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ueberuns/ueber-uns-lora/*",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ueberuns/geschichte-lora",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ueberuns/struktur",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ueberuns/datenschutzerklaerung",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/kontakt/links",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/kontakt/suchen",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/kontakt",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/kontakt/contacto",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/kontakt/iletisim",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/kontakt/contact",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/kontakt/adressaenderung",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/index.php",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ueberuns/ueber-uns-lora",
    component: component_45stubnO9MU04yTU
  }
]