<template>
  <NuxtLayout>
    <NuxtPage :keepalive="keepAliveProps" />
  </NuxtLayout>
</template>

<script lang="ts" setup>

const route = useRoute()
const config = useRuntimeConfig()

const keepAliveProps: keepAliveProps = {
  max: 2,
  exclude: [
    'CatchAll'
  ]
}

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Radio LoRa` : 'Radio LoRa'
  }
})

const metadata = {
  title: 'Radio LoRa',
  description: 'Radio LoRa ist ein nicht-kommerzielles Lokalradio für den Grossraum Zürich und das älteste Gemeinschaftsradio der Schweiz. Rund 300 engagierte Sendungsmacher_innen senden 24 Stunden am Tag aus unseren Studios im Kreis 4 - und das in 20 verschiedenen Sprachen.',
  image: `${config.public.appUrl}/images/social-sharing.png`
}

useSeoMeta({
  description: metadata.description,
  ogDescription: metadata.description,
  ogImage: metadata.image,
  twitterCard: 'summary_large_image'
})

watch(
  () => route.path,
  () => {
    document.body.setAttribute('tabindex', '-1')
    document.body.focus()
    setTimeout(() => document.body.removeAttribute('tabindex'), 1000)
  }
)
</script>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
