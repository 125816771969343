export default defineNuxtPlugin((NuxtApp) => {
  const state = useGraphqlState()
  const event = useRequestEvent()

  if (!state) {
    return
  }

  state.fetchOptions = {
    onResponse ({ response }) {
      if (response?._data?.__cacheTags !== undefined) {
        // Adding surrogate-key header
        useCDNHeaders((helper) => {
          helper
            .addTags(response?._data?.__cacheTags)
        }, event)
      }
    }
  }
})
