import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlockAccordion, LazyBlockAudio, LazyBlockCta, LazyBlockEpisode, LazyBlockForm, LazyBlockFormItemCheckbox, LazyBlockFormItemCheckboxes, LazyBlockFormItemEmail, LazyBlockFormItemErrors, LazyBlockFormItemFieldset, LazyBlockFormItemRadios, LazyBlockFormItemSelect, LazyBlockFormItemTel, LazyBlockFormItemTextarea, LazyBlockFormItemTextfield, LazyBlockFormItemWebformActions, LazyBlockFormItemWebformFlexbox, LazyBlockFormItemWebformMarkup, LazyBlockImage, LazyBlockLinksDownloads, LazyBlockMap, LazyBlockProgrammeList, LazyBlockRaiseNow, LazyBlockSlideshow, LazyBlockTeaser, LazyBlockTeasers, LazyBlockText, LazyBlockTitle, LazyBlockVideo, LazyNuxtIcon, LazyRenderCacheable } from '#components'
const lazyGlobalComponents = [
  ["BlockAccordion", LazyBlockAccordion],
["BlockAudio", LazyBlockAudio],
["BlockCta", LazyBlockCta],
["BlockEpisode", LazyBlockEpisode],
["BlockForm", LazyBlockForm],
["BlockFormItemCheckbox", LazyBlockFormItemCheckbox],
["BlockFormItemCheckboxes", LazyBlockFormItemCheckboxes],
["BlockFormItemEmail", LazyBlockFormItemEmail],
["BlockFormItemErrors", LazyBlockFormItemErrors],
["BlockFormItemFieldset", LazyBlockFormItemFieldset],
["BlockFormItemRadios", LazyBlockFormItemRadios],
["BlockFormItemSelect", LazyBlockFormItemSelect],
["BlockFormItemTel", LazyBlockFormItemTel],
["BlockFormItemTextarea", LazyBlockFormItemTextarea],
["BlockFormItemTextfield", LazyBlockFormItemTextfield],
["BlockFormItemWebformActions", LazyBlockFormItemWebformActions],
["BlockFormItemWebformFlexbox", LazyBlockFormItemWebformFlexbox],
["BlockFormItemWebformMarkup", LazyBlockFormItemWebformMarkup],
["BlockImage", LazyBlockImage],
["BlockLinksDownloads", LazyBlockLinksDownloads],
["BlockMap", LazyBlockMap],
["BlockProgrammeList", LazyBlockProgrammeList],
["BlockRaiseNow", LazyBlockRaiseNow],
["BlockSlideshow", LazyBlockSlideshow],
["BlockTeaser", LazyBlockTeaser],
["BlockTeasers", LazyBlockTeasers],
["BlockText", LazyBlockText],
["BlockTitle", LazyBlockTitle],
["BlockVideo", LazyBlockVideo],
["NuxtIcon", LazyNuxtIcon],
["RenderCacheable", LazyRenderCacheable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
