<template>
  <NuxtLayout>
    <div v-if="error" class="prose">
      <template v-if="error.statusCode === 404">
        <h1>404 - Seite nicht gefunden</h1>
      </template>
      <template v-else>
        <h1>{{ error.statusCode }} - Error</h1>
        <p>
          <strong>{{ error.message }}</strong>
        </p>
        <details v-if="!isProduction">
          <summary>Stack</summary>
          <div v-html="error.stack" />
        </details>
        <p>Leider ist etwas schief gelaufen.</p>
      </template>
      <p>
        <a href="/">
          Zur Startseite
        </a>
      </p>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const error = useError()

const isProduction = computed(() => {
  return (
    process.env.LAGOON_ENVIRONMENT_TYPE !== undefined &&
    process.env.LAGOON_ENVIRONMENT_TYPE === 'production'
  )
})
</script>
